
.header-top {
  background-color: #002856;
}

img.header-logo {
  content: url('../assets/hg_logo_3_color_orange_blue_white-01.png');
  height: 70px;
  padding: 5px 20px;
}

.header-title {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 28px;
}

.top-image {
  content: url('../assets/background-d.jpg');
  width: 100%;
}

ion-content {
  /* --padding-bottom: 0; */
  --padding-end: 0;
  --padding-start: 0;
  /* --padding-top: 0; */
}

.btn-front {
  /* background-color: #002856; */
  font-size: 4.3vw;
}

.btn-dial {
  /* background-color: #002856; */
  font-size: 4.8vw;
  font-weight: bold;
}
